import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Button } from 'reactstrap'
import { Empty } from 'antd'

import ConversationCard from './ChatCard'
import Loading from '../../components/templates/Loading'
import { getConversations } from '../../services/conversations'
import { handleError } from '../../helpers'

export default function ChatsList() {
	const [conversations, setConversations] = useState(null)
	const user = useSelector(state => state.auth.user)

	useEffect(() => {
		!conversations && getConversations({
			'filter[user_id]': user.id,
			include: 'user,intent'
		})
			.then(res => setConversations(res.data.data))
			.catch(error => handleError(error))
	})

	return (
		<React.Fragment>
			{ conversations ? (
				<React.Fragment>
					{ conversations.length > 0 ? (
						<React.Fragment>
							{ conversations.map( conversation => (
								<ConversationCard 
									key={conversation.id}
									conversation={conversation}
									reload={() => setConversations(null)}
								/>
							)) }
						</React.Fragment>
					) : (
						<div className="text-center">
							<Empty description="No tienes chats con nosotros" /> 
							<Link to="/chat/new">
								<Button color="primary" className="mt-3">
									Crear Chat Nuevo
								</Button>
							</Link>
						</div>
					)}
				</React.Fragment>
			) : <Loading /> }
		</React.Fragment>
	)
}