import { 
   USER_LOGIN, 
   USER_LOGOUT
} from "../actions/authActions"

const INITIAL_STATE = {}

const authReducer = (state = INITIAL_STATE, action) => {
   switch (action.type) {
      case USER_LOGIN:
         return { ...state, ...action.payload }

      case USER_LOGOUT:
         return INITIAL_STATE
         
      default:
         return { ...state }
   }
}

export default authReducer