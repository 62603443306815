import axios from "axios";
import {store} from "../store";

/*
 * Instancia con el servicio principal del backend
 */
const springApi = () => {
   const state = store.getState();
   const authToken = state.auth.token;

   var url = window.location.host.split('.')

   let domain = (url[0].includes('localhost')) ? 'spring_sacbot_api.test' : 'apichat.spring.com.co'

   return axios.create({
      baseURL: `https://${domain}/api`,
      timeout: 10000,
      headers: {
         Authorization: `Bearer ${authToken}`,
         "Content-Type": "application/json; charset=UTF-8",
      }
   });
};

export default springApi;
