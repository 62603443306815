import React, { useState } from 'react'

import Flow from '../../components/Flow'
import closeMessages from '../partials/closeMessages'

export default function DeliveryReachFlow() {
	const [survey, setSurvey] = useState(null)
	
	const messages = [
		{
			right: true,
			text: 'Cobertura de entregas a nivel nacional'
		},
		{
			text: 'Puedes consultar esta información abriendo este link:'
		},
		{
			right: true,
			option: {
				label: 'Abrir link con información',
				onClick: () => window.open('https://colchonesspring.vteximg.com.br/arquivos/spring-envio-gratis-tren-de-despachos.pdf?v=637546101796470000')
			}
		},
		...closeMessages(true, 1000, score => setSurvey({ ...survey, score }))
	]
	
	return <Flow messages={messages} survey={survey} setSurvey={setSurvey} />
}