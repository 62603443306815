import springApi from './springApi'

export const getConversation = (id, params) => (
	new Promise((resolve, reject) => {
		springApi()
			.get(`/conversations/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getConversations = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/conversations', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeConversation = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/conversations', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteConversation = id => (
	new Promise((resolve, reject) => {
		springApi()
			.delete(`/conversations/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)