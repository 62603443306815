import React, { useState } from 'react'

import Flow from '../../components/Flow'
import closeMessages from '../partials/closeMessages'

export default function UnsubscribeFlow() {
	const [survey, setSurvey] = useState(null)
	
	const messages = [
		{
			right: true,
			text: 'Cancelar suscripción de correos publicitarios'
		},
		{
			text: 'Puedes cancelar tu suscripción entrando a este link:'
		},
		{
			right: true,
			option: {
				label: 'Ingresa a este link',
				onClick: () => window.open('https://momentos.spring.com.co/desuscribirse')
			}
		},
		...closeMessages(true, 1000, score => setSurvey({ ...survey, score }))
	]
	
	return <Flow messages={messages} survey={survey} setSurvey={setSurvey} />
}