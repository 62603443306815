import React, { useState } from 'react'

import Flow from '../../components/Flow'
import closeMessages from '../partials/closeMessages'

export default function HygienitechFlow() {
	const [survey, setSurvey] = useState(null)
	
	const messages = [
		{
			right: true,
			text: 'Hygienitech: servicio de higienización de los colchones'
		},
		{
			text: 'El sistema Hygienitech elimina de forma segura ácaros del polvo, bacterias, virus, polen, moho, hongos y esporas de hongos y muchos otros contaminantes nocivos que se encuentran en colchones y artículos tapizados, así como en superficies duras no porosas que se encuentran en hogares, hoteles y todo tipo de múltiples instalaciones de la cama.',
			delay: 500,
		},
		{
			text: 'Este servicio lo manejamos únicamente en Bogotá',
			delay: 800
		},
		{
			right: true,
			option: {
				label: 'Quiero saber más de Hygienitech',
				onClick: () => window.open('https://www.colchonesspring.com.co/hygienitech/informacion')
			}
		},
		...closeMessages(true, 1000, score => setSurvey({ ...survey, score }))
	]
	
	return <Flow messages={messages} survey={survey} setSurvey={setSurvey} />
}