import React, { useState } from 'react'

import Flow from '../components/Flow'
import PasswordForm from './partials/PasswordForm'
import RegisterForm from './partials/RegisterForm'

import { searchByMobile } from '../services/users'
import { handleError } from '../helpers'

export default function LoginFlow() {
	const [loading, setLoading] = useState(false)
	const [message, setMessage] = useState('')
	const [mobile, setMobile] = useState('')
	const [user, setUser] = useState(null)

	const onMobileSubmit = () => {
		if(mobile.length !== 10){ 
			setMessage('Ingresa un celular válido de 10 dígitos')
		} else {
			setLoading(true)
			setMessage('')
			searchByMobile({ mobile })
				.then(res => {
					setLoading(false)
					setUser(res.data.data)
				})
				.catch(error => {
					setLoading(false)
					handleError(error)
				})
		}
	}

	const messages = [
		{
			text: "Ingresa los siguientes datos",
		},
		{
			right: true,
			text: "Mi número celular es:",
			delay: 1000,
		},
		{
			right: true,
			visible: !user ? true : false,						
			input: { 
				type: 'number',
				value: mobile,
				onChange: e => setMobile(e.target.value),
			},
			loading: loading,
			errorMessage: message,
			onSubmit: () => onMobileSubmit(),
			delay: 1000,		
		},
		{
			right: true,
			visible: user ? true : false,
			text: mobile,
			onRollBack: () => setUser(null)
		},
		{
			visible: user ? true : false,
			text: "Gracias, estamos verificando",
			delay: 500
		},
		{
			visible: user ? true : false,
			delay: 1000,
			render: () => user.document ? (
				<PasswordForm user={user} />	
			) : (
				<RegisterForm mobile={mobile} />
			)
		}
	]

	return <Flow messages={messages} />
}