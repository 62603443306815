import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import Flow from '../../components/Flow'
import closeMessages from '../partials/closeMessages'

import { storeConversation } from '../../services/conversations'
import { handleError } from '../../helpers'

export default function ProductDetailsFlow() {
	const auth = useSelector(state => state.auth)
	const [detailSelected, setDetailSelected] = useState(null)
	const [survey, setSurvey] = useState(null)
	const history = useHistory()

	const handleReachStore = () => {
		storeConversation({
			user_id: auth.user.id,
			intent_id: 14
		})
			.then(res => history.push(`/chat/${res.data.data.id}`))
			.catch(error => handleError(error))
	}
	
	const messages = [
		{
			right: true,
			text: 'Consulta sobre productos y sus características'
		},
		{
			text: '¿Qué tipo de información buscas?'
		},
		{
			visible: !detailSelected,
			right: true,
			option: { 
				label: 'Descripción general y medidas',
				onClick: () => setDetailSelected('descripción general y medidas')
			}
		},
		{
			visible: !detailSelected,
			right: true,
			option: { 
				label: 'Composición del producto',
				onClick: () => setDetailSelected('composición del producto')
			}
		},
		{
			visible: !detailSelected,
			right: true,
			option: { 
				label: 'Garantía, instrucciones de uso y cuidados',
				onClick: () => setDetailSelected('garantía, instrucciones de uso y cuidados') 
			}
		},
		{
			visible: detailSelected,
			right: true,
			text: detailSelected,
			onRollBack: () => setDetailSelected(null)
		},
		{
			visible: detailSelected,
			text: `Para encontrar la información de ${detailSelected} debes:`,
			delay: 500
		},
		{
			visible: detailSelected,
			text: '1. Abrir nuestro sitio web y buscar el producto.',
			delay: 1500
		},
		{
			right: true,
			option: {
				label: 'Abrir Sitio Web',
				onClick: () => window.open('https://www.colchonesspring.com.co')
			},
			delay: 1500
		},
		{
			visible: detailSelected,
			text: `2. Dar click en "Ver mas" y ahí encontrarás la información de ${detailSelected}`,
			delay: 2000
		},
		{
			visible: auth.user ? true : false,
			text: 'O si prefieres puedes encontrar la tienda más cercana para llamar, visitar o escribirles',
			delay: 2000
		},
		{
			visible: auth.user ? true : false,
			right: true,
			delay: 2000,
			option: {
				label: 'Encontrar tienda cercana',
				onClick: handleReachStore
			}
		},
		...closeMessages(detailSelected, 2500, score => setSurvey({ ...survey, score }))
	]
	
	return <Flow messages={messages} survey={survey} setSurvey={setSurvey} />
}