import React, { useState, useEffect } from 'react'
import { Form, InputGroup, Button } from 'react-bootstrap'
import { faPaperPlane, faRedo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export default function MessageBubble({ 
	visible = true,
	right, 
	text, 
	input,
	onSubmit,
	delay,
	loading,
	errorMessage,
	option,
	noFadeIn,
	render,
	onRollBack,
	defaultHint='',
	textStyle={},
}) {
	const [hint, setHint] = useState('')
	const [hide, setHide] = useState(delay ? true : false)

	useEffect(() => {
		if(!defaultHint){
			input && input.value ? setHint('') : setHint('Por favor ingresa tu respuesta')
		} else {
			setHint(defaultHint)
		}
	}, [ setHint, input, defaultHint ])

	useEffect(() => {
		delay && hide && setTimeout(() => {
			setHide(false)
		}, delay);
	})

	if(!visible) return null;

	if(render) return render();

	return (
		<div 
			className={`text-${right ? 'right' : 'left'} full-width ${!noFadeIn && 'fade-in'}`} 
			style={{ display: hide ? 'none' : 'block' }}
		>
			{ (text || input) && (
				<MessageContainer>
					{ text && (
						<MessageText align={right ? 'right' : 'left'}>
							<span style={textStyle}>{ text }</span>
						</MessageText>
					) }
					{ input && (
						<React.Fragment>
							<InputGroup className="mt-2">
								<Form.Control 
									placeholder="Escribe aquí..." 
									{ ...input }
								/>
								{ onSubmit && (
									<InputGroup.Append>
										<Button 
											variant="primary" 
											onClick={() => input.value && onSubmit()}
											disabled={loading}
										>
											<FontAwesomeIcon 
												icon={!loading ? faPaperPlane : faRedo} 
												spin={loading}
												className="mr-1"
											/>
											Enviar
										</Button>
									</InputGroup.Append>
								)}						
							</InputGroup>
							{ hint && !errorMessage && <Form.Text className="text-muted">{hint}</Form.Text> }	
							{ errorMessage && <Form.Text className="text-warning">{errorMessage}</Form.Text> }						
						</React.Fragment>
					)}
					{ onRollBack && (
						<BackLink onClick={onRollBack}>Editar</BackLink>
					)}
				</MessageContainer>
			)}
			{ option && (
				<MessageOption>
					<Button variant="outline-primary text-right" onClick={option.onClick}>
						{option.label}
					</Button>
				</MessageOption>
			)}
		</div>
	)
}

const MessageContainer = styled.div`
	max-width: 80%;
	margin-bottom: 5px;
	text-align: left;
  	display:inline-block;
`

const MessageText = styled.div`
	background-color: ${props => props.align === 'left' ? '#f1f0fb' : '#e9f4ff'};
	color: #4e4e4e;
	padding: 7px 10px;
	font-weight: 400;
	border-radius: 10px;
  	display: table-cell;
`

const MessageOption = styled.div`
	margin-bottom: 10px;
`

const BackLink = styled.div`
	text-align: right;
	margin-top: 5px;
	font-size: 12px;
	color: #e42537;
	font-style: italic;
	text-decoration: underline;
	cursor: pointer;
`