import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap'
import { Form } from 'react-bootstrap'
import { message as alertMessage } from 'antd'

import { storeSurvey } from '../../services/surveys'
import { handleError } from '../../helpers'

export default function SurveyModal({ visible, onClose, survey }) {
	const token = useSelector(state => state.auth.token)
	const [message, setMessage] = useState('')

	const onSubmit = () => {
		if(token){
			storeSurvey({ 
				score: survey.score,
				conversation_id: window.location.href.match(/([^/]*)\/*$/)[1],
				message
			})
				.then(() => {
					alertMessage.success('Gracias por enviar tu encuesta')
					setTimeout(() => window.location.href = '/chat/new', 500)
				})
				.catch(error => handleError(error))
		} else {
			alertMessage.success('Gracias por enviar tu encuesta')
			setTimeout(() => window.location.href = '/chat/new', 500)
		}
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose} centered>
				<ModalHeader toggle={onClose}>Encuesta de Satisfacción</ModalHeader>
				<ModalBody>
					<h6>{ survey.score > 4 ? '¿Qué fue lo qué más te ayudo?' : '¿En qué podemos mejorar?' }</h6>
					<Form.Group>
						<Form.Control 
							as="textarea"
							value={message}
							onChange={e => setMessage(e.target.value)}
							placeholder="Escribe aquí tus comentarios"
						/>
					</Form.Group>
					<Button color="primary" onClick={onSubmit}>Enviar encuesta y comenzar otro chat</Button>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}