import React from 'react'

import SurveyModal from '../chatbot/partials/SurveyModal'
import MessageBubble from '../components/MessageBubble'

export default function Flow({ messages, survey, setSurvey }) {
	return (
		<React.Fragment>
			{ messages.map( (message, i) => (
				<MessageBubble key={i} {...message} />
			)) }
			{ survey && survey.score && (
				<SurveyModal 
					visible={true}
					onClose={() => setSurvey({ ...survey, score: null })}
					survey={survey}
				/>
			)}
		</React.Fragment>
	)
}