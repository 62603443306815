import React, { useState } from 'react'

import Flow from '../../components/Flow'
import closeMessages from '../partials/closeMessages'

export default function BuyOnlineFlow() {
	const [extraInfo, setExtraInfo] = useState(null)
	const [survey, setSurvey] = useState(null)
	
	const messages = [
		{
			text: 'Para comprar en linea lo primero que necesitas es abrir nuestro sitio web'
		},
		{
			right: true,
			option: {
				label: 'Abrir Sitio Web',
				onClick: () => window.open('https://www.colchonesspring.com.co/')
			},
			delay: 500
		},
		{
			text: 'Selecciona los productos que deseas y finaliza tu compra',
			delay: 1500
		},
		{
			text: '¿Te interesa saber algo mas?',
			delay: 1500,			
		},
		{
			visible: !extraInfo ? true : false,
			delay: 1500,
			option: {
				label: 'Métodos de pago online',
				onClick: () => setExtraInfo({ 
					text: 'Métodos de pago online',
					response: 'Puedes pagar seguro con PSE, tarjetas de crédito y pago en efectivo en puntos Baloto y Efecty'
				})
			}
		},
		{
			visible: !extraInfo ? true : false,
			delay: 1500,
			option: {
				label: 'Guía de Tallas',
				onClick: () => setExtraInfo({ 
					text: 'Guía de Tallas',
					response: 'En nuestra página de colchones encuentras todas las medidas',
					option: {
						label: 'Ver página de colchones',
						onClick: () => window.open('https://www.colchonesspring.com.co/colchones?O=OrderByPriceASC')
					}
				})
			}
		},
		{
			visible: !extraInfo ? true : false,
			delay: 1500,
			option: {
				label: 'Preguntas Frecuentes',
				onClick: () => setExtraInfo({ 
					text: 'Preguntas Frecuentes',
					response: 'Encuentra un listado completo de preguntas frecuentes en este link',
					option: {
						label: 'Ingresa a este link',
						onClick: () => window.open('https://www.colchonesspring.com.co/links-interes/preguntas-frecuentes')
					}
				})
			}
		},
		{
			right: true,
			visible: extraInfo ? true : false,
			text: extraInfo && extraInfo.text,
			onRollBack: () => setExtraInfo(null),
			delay: 500,
		},
		{
			visible: extraInfo ? true : false,
			text: extraInfo && extraInfo.response,
			option: extraInfo && extraInfo.option,
			delay: 1500,
		},
		...closeMessages(extraInfo, 2000, score => setSurvey({ ...survey, score }))
	]
	
	return <Flow messages={messages} survey={survey} setSurvey={setSurvey} />
}