import React from 'react'
import { Spinner } from 'reactstrap'

import MessageBubble from '../MessageBubble'

export default function Loading(props) {
	return (
		<React.Fragment>
			<MessageBubble 
				{...props}
				text={<Spinner size="sm" />}
			/>
		</React.Fragment>
	)
}