import { } from "../actions/chatActions"

const INITIAL_STATE = { }

const chatReducer = (state = INITIAL_STATE, action) => {
   switch (action.type) {
      default:
         return { ...state }
   }
}

export default chatReducer