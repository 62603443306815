import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router'

import AuthFlow from '../chatbot/AuthFlow'
import LoginFlow from '../chatbot/LoginFlow'
import Logout from './partials/Logout'

export default function AuthPages() {
	const token = useSelector(state => state.auth.token)

	return (
		<Switch>
			<Route exact path="/auth/logout" component={Logout} />
			
			{ token && <Redirect to="/" />}

			<Route exact path="/auth" component={AuthFlow} />
			<Route exact path="/auth/login" component={LoginFlow} />

			<Redirect to="/404" />
		</Switch>
	)
}