import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import MessageBubble from '../../components/MessageBubble'
import { userLogin } from '../../store/actions/authActions'
import { register } from '../../services/users'
import { handleError } from '../../helpers'

export default function RegisterForm({ mobile }) {
	const [name, setName] = useState('')
	const [email, setEmail] = useState('')
	const [userDocument, setUserDocument] = useState('')
	const [loading, setLoading] = useState(false)
	const [message, setMessage] = useState('')
	const dispatch = useDispatch()

	const onSubmit = () => {
		if(!name || !email || !userDocument)
			setMessage('Todos los campos son requeridos')
		else 
			setLoading(true)

		name && email && userDocument && register({
			name,
			mobile,
			email,
			document: userDocument,
			password: userDocument,
			password_confirmation: userDocument
		})
			.then(res => dispatch(userLogin(res.data)))
			.catch(error => {
				handleError(error)
				setLoading(false)
				setMessage('Hubo un error. Por favor resfresca la página e intenta de nuevo')
			})
	}

	return (
		<React.Fragment>
			<MessageBubble 
				text="Que bueno que estés en este chat por primera vez" 
			/>
			<MessageBubble 
				text="Vamos a crear tu cuenta para darte la mejor experiencia"
			/>
			<MessageBubble 
				right
				text="Mis datos son:"
			/>
			<MessageBubble
				right
				input={{ 
					value: name,
					onChange: e => setName(e.target.value),
					placeholder: 'Nombre completo'
				}}
				loading={loading}
				defaultHint="Escribe tu nombre completo"
			/>
			<MessageBubble
				right
				input={{ 
					value: email,
					onChange: e => setEmail(e.target.value),
					placeholder: 'Correo electrónico'
				}}
				loading={loading}
				defaultHint="Escribe tu correo electrónico"
			/>
			<MessageBubble
				right
				input={{ 
					value: userDocument,
					onChange: e => setUserDocument(e.target.value),
					placeholder: 'Documento (Cédula)'
				}}
				defaultHint="Al enviar estas aceptando los términos y condiciones que puedes consultar en www.colchonespring.com"
				onSubmit={onSubmit}
				loading={loading}
				errorMessage={message}
			/>
		</React.Fragment>
	)
}