import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect } from 'react-router'
import { Spinner } from 'reactstrap'

import { userLogout } from '../../store/actions/authActions'
import { logout } from '../../services/users'

export default function Logout() {
	const dispatch = useDispatch()
	const token = useSelector(state => state.auth.token)

	useEffect(() => {
		const handleLogout = () => {
			dispatch(userLogout())
		}

		if(token) logout().finally(handleLogout)
		else handleLogout()
	})

	if(!token) return <Redirect to="/" />

	return (
		<React.Fragment>
			<div className="text-center">
				<Spinner size="lg" />
			</div>
		</React.Fragment>
	)
}