import React, { useState } from 'react'

import Flow from '../../components/Flow'
import closeMessages from '../partials/closeMessages'

export default function ContactUpdateFlow() {
	const [data, setData] = useState({ document:'', newMobile:'', newPhone:'', newAddress:'', newCity:'' })
	const [loading, setLoading] = useState(false)
	const [message, setMessage] = useState('')
	const [survey, setSurvey] = useState(null)

	const setValue = values => {
		setData({ ...data, ...values })
		// Store in database
	}

	const handleDocumentSearch = () => {
		setLoading(true)
		setValue({ contact: {} })
		setMessage('')		
		setLoading(false)
		window.scrollTo(0,document.body.scrollHeight)
	}

	const handleMobileUpdate = () => {
		setLoading(true)
		setValue({ isMobileUpdated: true })
		setMessage('')		
		setLoading(false)
		window.scrollTo(0,document.body.scrollHeight)
	}

	const handleAddressUpdate = () => {
		setLoading(true)
		setValue({ isAddressUpdated: true })
		setMessage('')		
		setLoading(false)
		window.scrollTo(0,document.body.scrollHeight)
	}
	
	const messages = [
		{
			right: true,
			text: 'Requiero cambio de telefono de contacto o dirección de entrega del pedido'
		},
		{
			text: 'Hola recuerda que solo podemos entregar a un destino el pedido completo'
		},
		{
			text: '¿Qué deseas cambiar el teléfono o la dirección de entrega?'
		},
		{
			visible: !data.changeType,
			right: true,
			option: {
				label: 'Teléfono de Contacto',
				onClick: () => setValue({ changeType: 'mobile', changeTypeLabel: 'Teléfono de Contacto' })
			}
		},
		{
			visible: !data.changeType,
			right: true,
			option: {
				label: 'Dirección de Entrega',
				onClick: () => setValue({ changeType: 'address', changeTypeLabel: 'Dirección de Entrega' })
			}
		},
		{
			visible: data.changeType,
			right: true,
			text: data.changeTypeLabel,
			delay: 500
		},
		{
			visible: data.changeType === 'mobile',
			text: 'Por favor registre su número cédula para validar sus datos',
		},
		{
			visible: data.changeType !== undefined && data.contact === undefined,
			right: true,
			input: { 
				value: data.document,
				onChange: e => setValue({ document: e.target.value }),
				placeholder: 'Ingrese su cédula'
			},
			loading: loading,
			errorMessage: message,
			onSubmit: handleDocumentSearch,
		},
		{
			visible: data.contact !== undefined,
			right: true,
			text: `Mi cédula es: ${data.document}`
		},
		// CAMBIO DE TELEFONO
		{
			visible: data.changeType === 'mobile' && data.contact !== undefined,
			text: `Su No. telefónico registrado es el siguiente: xxxxxxxx`,
			delay: 1000,
		},
		{
			visible: data.changeType === 'mobile' && data.contact !== undefined && data.mobileSubmited !== true,
			right: true,
			text: 'Deseo modificar por: ',
			input: { 
				value: data.newMobile,
				onChange: e => setValue({ newMobile: e.target.value }),
				placeholder: 'Ingrese su celular',
				type: 'number',
			},
			errorMessage: message,
			onSubmit: () => {
				if(data.newMobile.length === 10 && data.newMobile.toString().slice(0, 1) === '3'){
					setValue({ mobileSubmited: true })
					setMessage('')
				} else { setMessage('Debe ingresar un celular válido') }
			},
			delay: 1000,
		},
		{
			visible: data.mobileSubmited === true,
			right: true,
			text: `Nuevo celular: ${data.newMobile}`,
		},
		{
			visible: data.mobileSubmited === true && data.isMobileUpdated !== true,
			right: true,
			input: { 
				value: data.newPhone,
				onChange: e => setValue({ newPhone: e.target.value }),
				placeholder: 'Otro teléfono',
				type: 'number'
			},
			loading: loading,
			errorMessage: message,
			onSubmit: handleMobileUpdate,
			delay: 1000,
		},
		{
			visible: data.isMobileUpdated === true,
			right: true,
			text: `Teléfono secundario: ${data.newPhone}`
		},
		{
			visible: data.isMobileUpdated === true,
			text: 'Muchas gracias es el número que quedó registrado exitosamente.'
		},
		// CAMDIO DE DIRECCIÓN
		{
			visible: data.changeType === 'address' && data.contact !== undefined,
			text: `Su dirección y ciudad de entrega registrada es la siguiente: xxxxxxxx`,
			delay: 1000,
		},
		{
			visible: data.changeType === 'address' && data.contact !== undefined && data.addressSubmited !== true,
			right: true,
			text: 'Deseo modificar por: ',
			input: { 
				value: data.newAddress,
				onChange: e => setValue({ newAddress: e.target.value }),
				placeholder: 'Ingrese la dirección',
			},
			onSubmit: () => setValue({ addressSubmited: true }),
			delay: 1000,
		},
		{
			visible: data.addressSubmited === true,
			right: true,
			text: `Nueva dirección: ${data.newAddress}`,
		},
		{
			visible: data.addressSubmited === true && data.isAddressUpdated !== true,
			right: true,
			input: { 
				value: data.newCity,
				onChange: e => setValue({ newCity: e.target.value }),
				placeholder: 'Ciudad',
			},
			loading: loading,
			errorMessage: message,
			onSubmit: handleAddressUpdate,
			delay: 1000,
		},
		{
			visible: data.isAddressUpdated === true,
			right: true,
			text: `Ciudad: ${data.newCity}`
		},
		{
			visible: data.isAddressUpdated === true,
			text: `Muchas gracias la nueva dirección registrada para su entrega es ${data.newAddress} - ${data.newCity}`
		},
		...closeMessages(data.isAddressUpdated, 1000, score => setSurvey({ ...survey, score }))
	]
	
	return <Flow messages={messages} survey={survey} setSurvey={setSurvey} />
}