import React, { useState } from 'react'

import Flow from '../../components/Flow'
import closeMessages from '../partials/closeMessages'

export default function OpenPqrWebapp() {
	const [survey, setSurvey] = useState(null)
	
	const messages = [
		{
			right: true,
			text: 'Recuerda que un PQR es toda Petición, Queja o Reclamo que desees realizar a Spring.'
		},
		{
			text: 'Haz click en link en el siguiente link para radicar o dar seguimiento a tu PQR'
		},
		{
			right: true,
			option: {
				label: 'Ingresa a este link',
				onClick: () => window.open('https://pqr.colchonesspring.com.co/')
			}
		},
		...closeMessages(true, 1000, score => setSurvey({ ...survey, score }))
	]
	
	return <Flow messages={messages} survey={survey} setSurvey={setSurvey} />
}