import React, { useState, useEffect } from 'react'
import { Spinner } from 'reactstrap'

import ChatBot from '../../chatbot'
import { getConversation } from '../../services/conversations'
import { handleError } from '../../helpers'

export default function OpenChat({ id }) {
	const [conversation, setConversation] = useState(null)

	useEffect(() => {
		!conversation && getConversation(id, {
			include: 'intent'
		})
			.then(res => setConversation(res.data.data))
			.catch(error => handleError(error))
	}, [ conversation, id ])

	if(!conversation) return <Spinner size="lg" />

	return <ChatBot intent={conversation.intent.bot_key} />
}