import React from 'react'
import { Link } from 'react-router-dom'
import { message, Popconfirm } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'
import moment from 'moment'

import { deleteConversation } from '../../services/conversations'
import { handleError } from '../../helpers'

export default function ChatCard({ conversation, reload }) {
	
	const handleDelete = () => {
		deleteConversation(conversation.id)
			.then(() => {
				message.success('El chat ha sido eliminado')
				reload()
			})
			.catch(error => handleError(error))
	}
	
	return (
		<React.Fragment>
			
				<Container>
					<div className="float-right">
						<Popconfirm
							title="¿Seguro deseas eliminar el chat?"
							okText="Eliminar"
							cancelText="Cancelar"
							onConfirm={handleDelete}
							okButtonProps={{ danger:true }}
							placement="bottomLeft"
						>
							<FontAwesomeIcon className="text-link mt-2 ml-2" icon={faTrash} />
						</Popconfirm>
					</div>
					<Link to={`/chat/${conversation.id}`}>
						<p className="mb-0"><small>Tema: </small>{conversation.intent.name}</p>
						<small className="text-muted">Creado el {moment(conversation.created_at).format('YYYY-MMM-DD hh:mm A')}</small>
					</Link>
			</Container>
		</React.Fragment>
	)
}

const Container = styled.div`
	background-color: #fff;
	width: 100%;
	margin-bottom: 5px;
	padding: 7px 13px;
	border: 1px solid #e8e8e8;
`