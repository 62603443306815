import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import Flow from '../../components/Flow'
import { handleError } from '../../helpers'
import { storeConversation } from '../../services/conversations'
import closeMessages from '../partials/closeMessages'

export default function SalesChatFlow() {
	const auth = useSelector(state => state.auth)
	const [survey, setSurvey] = useState(null)
	const history = useHistory()

	const handleReachStore = () => {
		if(auth.user){
			storeConversation({
				user_id: auth.user.id,
				intent_id: 14
			})
				.then(res => history.push(`/chat/${res.data.data.id}`))
				.catch(error => handleError(error))
		} else {
			history.push('/chat/visits/reach_store')
		}
	}
	
	const messages = [
		{
			right: true,
			text: 'Chat para asesoría especializada en tu compra'
		},
		{
			text: 'Recibe la asesoría de uno de nuestros expertos del descanso.',
			delay: 500
		},
		{
			right: true,
			delay: 1000,
			option: {
				label: 'Abrir chat con experto del descanso',
				onClick: () => window.open('https://www.colchonesspring.com.co?oct8neOpen=1&assignedAgent=25158&assignedDept=15819')
			}
		},
		{
			text: 'Nuestro horario de atención por chat de lunes a sabado es de 9 am a 9 pm y domingos/festivos de 9 am a 6pm.',
			delay: 1500
		},
		{
			text: 'O si prefieres puedes encontrar la tienda más cercana para llamar, visitar o escribirles',
			delay: 2000
		},
		{
			right: true,
			delay: 2000,
			option: {
				label: 'Encontrar tienda cercana',
				onClick: handleReachStore
			}
		},
		...closeMessages(true, 2500, score => setSurvey({ ...survey, score }))
	]
	
	return <Flow messages={messages} survey={survey} setSurvey={setSurvey} />
}