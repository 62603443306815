import React from 'react'
import { useSelector } from 'react-redux'

import Flow from '../components/Flow'
import IntentSelector from './partials/IntentSelector'

export default function NewChatFlow() {
	const user = useSelector(state => state.auth.user)

	const messages = [
		{
			text: `Hola${user ? ` ${user.name.split(" ")[0]}` : ''}, ¿En que puedo ayudarte hoy?`
		},
		{
			text: 'Selecciona la opción que necesites'
		},
		{
			right: true,
			text: "Quisiera ayuda con:",
			delay: 500,
		},
		{
			right: true,
			render: () => (
				<IntentSelector />
			)
		}
	]

	return <Flow messages={messages} />
}