import React, { useState } from 'react'

import Flow from '../../components/Flow'
import closeMessages from '../partials/closeMessages'

export default function OpenWarrantyReqWebapp() {
	const [survey, setSurvey] = useState(null)
	
	const messages = [
		{
			right: true,
			text: 'Para radicar o realizar seguimiento a una solicitud de garantía o seguimiento'
		},
		{
			text: 'Haga click en link en el siguiente link'
		},
		{
			right: true,
			option: {
				label: 'Ingresa a este link',
				onClick: () => window.open('https://pqr.colchonesspring.com.co/')
			}
		},
		...closeMessages(true, 1000, score => setSurvey({ ...survey, score }))
	]
	
	return <Flow messages={messages} survey={survey} setSurvey={setSurvey} />
}