import React, { useEffect } from "react"
import { Switch, Route, Redirect } from "react-router-dom"
import { useSelector } from "react-redux"
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

import MainLayout from "./layouts/MainLayout"
import AuthPages from "./pages/AuthPages"
import ChatPages from "./pages/ChatPages"
import NotFoundPage from "./pages/NotFoundPage"

Bugsnag.start({
	apiKey: '7eb4911f97839f9735f40d76519db47b',
	plugins: [new BugsnagPluginReact()]
})

const ErrorBoundary = Bugsnag.getPlugin('react')
  .createErrorBoundary(React)

export default function App() {
	const token = useSelector(state => state.auth.token)

	useEffect(() => {
		window.location.replace('https://wa.me/5713077053')
	})

	return (
		<React.Fragment>		
			<ErrorBoundary>
				<MainLayout>					
					<Switch>
						<Redirect exact path="/" to={token ? '/chat' : '/auth'} />
						<Route exact path="/404" component={NotFoundPage} />

						<Route path="/auth" component={AuthPages} />
						<Route path="/chat" component={ChatPages} />

						<Redirect to="/404" />
					</Switch>				
				</MainLayout>
			</ErrorBoundary>
		</React.Fragment>
	);	
}