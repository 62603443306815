import React, { useState } from 'react'

import Flow from '../../components/Flow'
import closeMessages from '../partials/closeMessages'

export default function OrderStatusFlow() {
	const [survey, setSurvey] = useState(null)
	
	const messages = [
		{
			right: true,
			text: 'Seguimiento al estado del pedido'
		},
		{
			right: true,
			text: 'Haz click y conoce donde va el proceso de entrega de tu pedido.'
		},
		{
			right: true,
			option: {
				label: 'Conocer el estado de mi pedido',
				onClick: () => window.open('http://rastreo.spring.com.co/')
			}
		},
		...closeMessages(true, 1000, score => setSurvey({ ...survey, score }))
	]
	
	return <Flow messages={messages} survey={survey} setSurvey={setSurvey} />
}