import React, {useState} from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faArrowLeft, faBars, faTimes } from '@fortawesome/free-solid-svg-icons'

import LogoSpring from '../assets/images/logo_spring.png'
import avatarImg from '../assets/images/avatar3.jpg'
import MainMenu from './partials/MainMenu'

export default function MainLayout({ children }) {
	const [openMenu, setOpenMenu] = useState(false)

	return (
		<Container>
			<TopBar className="no-print">
				<div className="float-left mt-3">
					<FontAwesomeIcon 
						icon={ openMenu ? faTimes : faBars}
						onClick={()=>setOpenMenu(!openMenu)}
						className="nav-toggle-icon mr-2"
					/>
					<span 
						className="text-link hide-sm" 
						onClick={()=>setOpenMenu(!openMenu)}
					>
						Menú
					</span>
				</div>
				<img src={LogoSpring} alt="Logo Spring" width="120px" className="mt-1" />
				<div className="float-right mt-1">
					<div style={{ width: '50px', heigth: '50px', borderRadius: '50px' }}>
						<img src={avatarImg} alt="avatar" width="100%" />
					</div>
				</div>
			</TopBar>
			{ openMenu && (
				<MainMenu closeMenu={()=>setOpenMenu(false)} />
			)}
			<MainContent>
				{children}
			</MainContent>
			<GoBack onClick={() => window.location.href = '/chat/new'}>
				<FontAwesomeIcon icon={faArrowLeft} />
			</GoBack>
			<GoToBottom onClick={() => window.scrollTo(0,document.body.scrollHeight)}>
				<FontAwesomeIcon icon={faArrowDown} />
			</GoToBottom>
		</Container>
	)
}

const Container = styled.div`
	width: 100%;
	min-height: 100vh;
	background-color: #f8f8f8;
	background-image: url('https://momentos.spring.com.co/img/bkg_clouds2.jpg');
	background-size: cover;
	background-repeat: repeat;
`

const TopBar = styled.div`
	width: 100%;
	padding: 5px 30px;
	background-color: #fff;
	color: #e42537;
	position: fixed;
	top: 0px;
	z-index: 10;
	text-align: center;
`

const MainContent = styled.div`
	padding: 30px;
	width: 100%;
	max-width: 500px;
	margin: auto;
	margin-top: 67px;
	background-color: #ffffff90;
	min-height: 90vh;
	padding-bottom: 50px;
`

const GoToBottom = styled.div`
	width: 39px;
	height: 39px;
	border-radius: 100px;
	text-align: center;
	padding-top: 5px;
	background-color: #e42537;
	color: #fff;
	position: fixed;
	bottom: 20px;
	right: 20px;	
	&:hover {
		cursor: pointer;
		opacity: 0.7;
	}
	z-index: 999;
	border: 2px solid #fff;
`

const GoBack = styled.div`
	width: 39px;
	height: 39px;
	border-radius: 100px;
	text-align: center;
	padding-top: 5px;
	background-color: #333;
	color: #fff;
	position: fixed;
	bottom: 20px;
	left: 20px;	
	&:hover {
		cursor: pointer;
		opacity: 0.7;
	}
	z-index: 999;
	border: 2px solid #fff;
`