import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import MessageBubble from '../../components/MessageBubble'
import { userLogin } from '../../store/actions/authActions'
import { login } from '../../services/users'

export default function PasswordForm({ user }) {
	const [password, setPassword] = useState('')
	const [loading, setLoading] = useState(false)
	const [message, setMessage] = useState('')
	const dispatch = useDispatch()

	const onPasswordSubmit = () => {
		setLoading(true)
		login({
			document: user.document,
			password
		})
			.then(res => {
				setLoading(false)
				dispatch(userLogin(res.data))
			})
			.catch(error => {
				setLoading(false)
				!user.is_admin ? (
					setMessage(`Oops. No coincide tu celular y cédula. Pista: tu cédula inicia por ${user.document.substring(0, 4)}***`)
				) : (
					setMessage(`Oops. No coincide tu celular y contraseña. Pista: es la misma contraseña para ingresar al administrador`)
				)
			})
	}

	return (
		<React.Fragment>
			<MessageBubble 
				text={`Hola, ${user.name.split(" ")[0]}. Solo para saber que eres tu, por favor ingresa tu ${user.is_admin ? 'contraseña' : 'cédula'}`}
				delay={1500}
			/>
			<MessageBubble 
				right
				delay={2000}
				text={`Mi ${user.is_admin ? 'contraseña' : 'cédula'} es:`}
			/>
			<MessageBubble 
				right
				delay={2000}
				input={{ 
					value: password,
					onChange: e => setPassword(e.target.value),
					type: user.is_admin ? 'password' : 'number'
				}}
				onSubmit={onPasswordSubmit}
				loading={loading}
				errorMessage={message}
			/>
		</React.Fragment>
	)
}