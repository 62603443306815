export const closeMessages = (show, delay, setScore) => ([
	{
		visible: show,
		text: '¿Resolvimos tu duda?',
		delay
	},
	{
		visible: show,
		option: {
			label: 'Sí, gracias',
			onClick: () => setScore(5)
		},
		delay
	},
	{
		visible: show,
		option: {
			label: 'No o no del todo',
			onClick: () => setScore(1)
		},
		delay
	},
	{
		visible: show,
		option: {
			label: 'Deseo continuar en el chat',
			onClick: () => window.location.href = "/chat/new"
		},
		delay
	}
])

export default closeMessages