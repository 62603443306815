import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import Channels from '../../components/templates/Channels'
import Loading from '../../components/templates/Loading'
import MessageBubble from '../../components/MessageBubble'

import { storeConversation } from '../../services/conversations'
import { getIntents } from '../../services/intents'
import { handleError } from '../../helpers'
import { storeVisitLog } from '../../services/visitLogs'

export default function IntentSelector() {
	const [intents, setIntents] = useState(null)
	const [selectedIntents, setSelectedIntents] = useState([])
	const [currentParentId, setCurrentParentId] = useState(null)
	const user = useSelector(state => state.auth.user)
	const history = useHistory()

	useEffect(() => {
		!intents && getIntents({
			'filter[parent_intent_id]': currentParentId,
			'filter[initial]': currentParentId ? null : 1,
			'filter[active]': 1,
			sort: 'created_at'
		})
			.then(res => setIntents(res.data.data))
			.catch(error => handleError(error))
	}, [ intents, currentParentId ])

	const startFlow = (intentId, intentSlug) => {
		if(user) {
			storeConversation({
				user_id: user.id,
				intent_id: intentId
			})
				.then(res => history.push(`/chat/${res.data.data.id}`))
				.catch(error => handleError(error))
		} else {
			storeVisitLog({ intent_id: intentId })
				.then(() => history.push(`/chat/visits/${intentSlug}`))
				.catch(error => handleError(error))
		}
	}

	const handleIntentClick = intent => {
		setSelectedIntents([ ...selectedIntents, intent ])
		setCurrentParentId(intent.id)					

		if (intent.bot_key)
			startFlow(intent.id, intent.bot_key)
		else 
			setIntents(null)

		window.scrollTo(0,document.body.scrollHeight)
	}

	return (
		<React.Fragment>			
			{ selectedIntents.map(selectedIntent => (
				<MessageBubble 
					key={selectedIntent.id}
					text={selectedIntent.name}
					right
				/>
			)) }
			{ intents ? (
				<React.Fragment>
					{ intents.length > 0 ? (
						<React.Fragment>
							{ intents.map( intent => (
								<MessageBubble
									key={intent.id}
									right
									delay={1000}
									option={{
										label: intent.name,
										onClick: () => handleIntentClick(intent)
									}}
								/>
							)) }
						</React.Fragment>
					) : <Channels delay={1000} />}
				</React.Fragment>
			) : <Loading right />}
			{/* { user && selectedIntents.length === 0 && (
				<MessageBubble 
					text={`O si ya habíamos conversado puedes ver tus:`}
					delay={2000}
					option={{ 
						label: 'Conversaciones pasadas', 
						onClick: () => history.push('/chat/list')
					}}
				/>
			)} */}
		</React.Fragment>
	)
}