import React from 'react'
import { useHistory } from 'react-router'

import MessageBubble from '../MessageBubble'

export default function Channels(props) {
	const history = useHistory()

	return (
		<React.Fragment>
			<MessageBubble {...props}
				text="Puedes hablar con un asesor" 
			/>
			<MessageBubble {...props}
				option={{ 
					label: 'Abrir Whatsapp', 
					onClick: () => window.open('') 
				}}
			/>
			<MessageBubble {...props}
				option={{ 
					label: 'Llamar Call Center', 
					onClick: () => window.open('') 
				}}
			/>
			<MessageBubble 
				{...props}
				option={{ 
					label: 'Comenzar de nuevo', 
					onClick: () => window.location.pathname === '/' ? window.location.reload() : history.push('/') 
				}}
			/>
		</React.Fragment>
	)
}