import springApi from './springApi'

export const login = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/auth/login', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const logout = () => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/auth/logout')
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchByMobile = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/auth/search-by-mobile', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const register = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/auth/register', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)