import React, { useState } from 'react'

import Flow from '../../components/Flow'
import closeMessages from '../partials/closeMessages'
import { capitalize } from '../../helpers'

export default function PaymentTypesFlow() {
	const [channel, setChannel] = useState(null)
	const [survey, setSurvey] = useState(null)
	
	const messages = [
		{
			right: true,
			text: 'Consulta de medios de pago'
		},
		{
			text: '¿Donde vas a realizar tu compra?'
		},
		{
			visible: !channel,
			right: true,
			option: {
				label: 'En una tienda Spring',
				onClick: () => setChannel('offline')
			}
		},
		{
			visible: !channel,
			right: true,
			option: {
				label: 'Online en www.colchonesspring.com.co',
				onClick: () => setChannel('online')
			},
		},
		{
			visible: channel,
			right: true,
			text: capitalize(channel === 'online' ? 'En la tienda online' : 'En una tienda Spring'),
			onRollBack: () => setChannel(null)
		},
		{
			visible: channel === 'online',
			text: 'En www.colchonesspring.com.co puedes pagar con tarjeta de crédito, PSE (Cuenta de ahorros o cuenta corriente), o en efectivo a través de un Efecty o Baloto, Crédito Spring Fast. Sólo debes escoger el medio de pago en el checkout.',
			delay: 1000,
			option: {
				label: 'Abrir Tienda Online',
				onClick: () => window.open('https://www.colchonesspring.com.co')
			}
		},
		{
			visible: channel === 'offline',
			text: 'En nuestras tiendas puedes pagar con tarjeta de crédito o en efectivo, crédito Spring Fast, vanti, codensa o Sodexo. Solo debes acercate a la tienda más cercana.',
			delay: 1000,
		},
		...closeMessages(channel, 1500, score => setSurvey({ ...survey, score }))
	]
	
	return <Flow messages={messages} survey={survey} setSurvey={setSurvey} />
}