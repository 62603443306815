import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import Flow from '../components/Flow'
import PasswordForm from './partials/PasswordForm'
import RegisterForm from './partials/RegisterForm'

import { searchByMobile } from '../services/users'
import { handleError } from '../helpers'

export default function AuthFlow() {
	const [loading, setLoading] = useState(false)
	const [message, setMessage] = useState('')
	const [mobile, setMobile] = useState('')
	const [user, setUser] = useState(null)
	const [acceptTyc, setAcceptTyc] = useState(false)
	const [isClientText, setIsClientText] = useState(null)
	const history = useHistory()

	const onMobileSubmit = () => {
		if(mobile.length !== 10){ 
			setMessage('Ingresa un celular válido de 10 dígitos')
		} else {
			setLoading(true)
			setMessage('')
			searchByMobile({ mobile })
				.then(res => {
					setLoading(false)
					setUser(res.data.data)
				})
				.catch(error => {
					setLoading(false)
					handleError(error)
				})
		}
	}

	const messages = [
		{
			text: "¡Hola! Bienvenido a colchones Spring",
		},
		{
			text: `Al hacer uso de este canal, autorizo de manera voluntaria, previa, expresa e informada a INDUSTRIAS SPRING S.A.S. identificada con NIT. 860.000.794-1 en calidad de RESPONSABLE, para tratar tus datos personales de acuerdo con su Política de Tratamiento de Datos Personales. INDUSTRIAS SPRING S.A.S. queda autorizado para recolectar, compilar, almacenar, usar, circular, compartir, comunicar, procesar, actualizar, cruzar, transferir, transmitir, depurar, suprimir y disponer mis datos personales aquí suministrados, de acuerdo con las finalidades relacionadas con el objeto social de la Compañía y en especial para responder a mis inquietudes allegadas a través de este canal, y utilizar mis datos personales con la finalidad de mantener contacto y remitir información de interés a través de boletines electrónicos y/o mensajes de texto a mi número celular. Finalmente declaro que la información y datos personales que he dispuesto para el tratamiento por parte de INDUSTRIAS SPRING S.A.S. la he suministrado de forma voluntaria y es verídica.

			Como titular de datos personales he sido informado de que la posibilidad de ejercer cualquiera de los derechos mencionados, dirigiendo de forma gratuita una Consulta y/o Comunicación en este sentido a la dirección electrónica protecciondedatos@spring.com y de manera personal en Autopista Medellin Ent. 2.2 Km. Al Occidente Del Rio Bogotá Costado Norte del Municipio de Cota.`,
			textStyle: { fontSize: 12 }
		},
		{
			visible: !acceptTyc,
			right: true,
			option: {
				label: 'Acepto',
				onClick: () => setAcceptTyc(true)
			}
		},
		{
			visible: acceptTyc,
			right: true,
			text: 'Acepto'
		},
		{
			visible: acceptTyc,
			text: '¿Ya eres cliente de Colchones Spring?'
		},
		{
			visible: acceptTyc && !isClientText,
			right: true,
			option: {
				label: 'Si, ya soy cliente',
				onClick: () => setIsClientText('Si, ya soy cliente')
			}
		},
		{
			visible: acceptTyc && !isClientText,
			right: true,
			option: {
				label: 'No, aún no he comprado en Spring',
				onClick: () => history.push('/chat')
			}
		},
		{
			visible: acceptTyc && isClientText,
			right: true,
			text: isClientText
		},
		{
			visible: acceptTyc && isClientText && isClientText === 'No, aún no he comprado en Spring',
			text: 'Gracias por entrar a Spring, queremos darte el mejor servicio y para eso necesitamos hacer una evaluación constante del mismo. Por eso necesitamos los siguientes datos:'
		},
		{
			visible: acceptTyc && isClientText && isClientText !== 'No, aún no he comprado en Spring',
			text: "Aquí estamos para ayudarte con todo lo que requieras",
		},
		{
			visible: acceptTyc && isClientText && isClientText !== 'No, aún no he comprado en Spring',
			text: "Para comenzar necesitamos validar los siguientes datos",
			delay: 1000,
		},
		{
			visible: acceptTyc && isClientText,
			right: true,
			text: "Mi número celular es:",
			delay: 2000,
		},
		{
			right: true,
			visible: acceptTyc && isClientText && !user ? true : false,						
			input: { 
				type: 'number',
				value: mobile,
				onChange: e => setMobile(e.target.value),
			},
			loading: loading,
			errorMessage: message,
			onSubmit: () => onMobileSubmit(),
			delay: 2000,		
		},
		{
			right: true,
			visible: acceptTyc && isClientText && user ? true : false,
			text: mobile,
			onRollBack: () => setUser(null)
		},
		{
			visible: acceptTyc && isClientText && user ? true : false,
			text: "Gracias, estamos verificando",
			delay: 500
		},
		{
			visible: acceptTyc && isClientText && user ? true : false,
			delay: 1000,
			render: () => user.document ? (
				<PasswordForm user={user} />	
			) : (
				<RegisterForm mobile={mobile} />
			)
		}
	]

	return <Flow messages={messages} />
}