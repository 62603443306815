import React from 'react'

import MessageBubble from '../../components/MessageBubble'

export default function StoresList({ stores, meta, setSelectedStore, handleNextPage, handleReload }) {
	return (
		<React.Fragment>
			{ stores.length > 0 ? (
				<React.Fragment>
					{ stores.map( store => (
						<MessageBubble 
							key={store.id}
							option={{ 
								label: store.name,
								onClick: () => setSelectedStore(store)
							}}
						/>
					)) }
					{ meta && parseInt(meta.per_page) < meta.total && (
						<MessageBubble 
							option={{ 
								label: 'Mostrar más',
								onClick: handleNextPage
							}}
						/>
					)}
				</React.Fragment>
			) : (
				<MessageBubble 
					text="Lo lamento no encontramos tiendas con esta busqueda"
					option={{ 
						label: 'Buscar de nuevo',
						onClick: handleReload
					}} 
				/>
			)}
		</React.Fragment>
	)
}