import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

import authReducer from './authReducer'
import chatReducer from './chatReducer'
 
const rootReducer = combineReducers({
  auth: persistReducer({
    key: 'token',
    storage: storage
  }, authReducer),
  chat: persistReducer({
    key: 'chat',
    storage: storage
  }, chatReducer),
});
 
export default rootReducer;