import React, { useState } from 'react'

import Flow from '../../components/Flow'
import StoresList from '../partials/StoresList'
import closeMessages from '../partials/closeMessages'

import { getStores } from '../../services/stores'
import { handleError } from '../../helpers'

export default function ReachStoreFlow() {
	const [term, setTerm] = useState('')
	const [cityTerm, setCityTerm] = useState('')
	const [stores, setStores] = useState(null)
	const [perPage, setPerPage] = useState(5)
	const [meta, setMeta] = useState(null)
	const [loading, setLoading] = useState(false)
	const [message, setMessage] = useState('')
	const [selectedStore, setSelectedStore] = useState(null)
	const [citySelected, setCitySelected] = useState(null)
	const [survey, setSurvey] = useState(null)

	const handleSearch = (perPageSearch) => {
		setLoading(true)
		getStores({
			'filter[city]': cityTerm,
			'filter[keyword]': term,
			per_page: perPageSearch,
			sort: 'channel-relevance'
		})
			.then(res => {
				setStores(res.data.data)
				setMeta(res.data.meta)
			})
			.catch(error => {
				handleError(error)
				setMessage('No se pudo realizar la busqueda. Por favor, refresca la página e intenta de nuevo.')
			})
			.finally(() => setLoading(false))
	}

	const handleNextPage = () => {
		setPerPage(perPage + 5)
		handleSearch(perPage + 5)
	}

	const handleReload = () => {
		setSelectedStore(null)
		setStores(null)
		setPerPage(5)
	}

	const messages = [
		{
			text: 'Encuentra en nuestro sitio web todas las tiendas abiertas',
			option: {
				label: 'Ingresa a este link',
				onClick: () => window.open('https://www.colchonesspring.com.co/tiendas-abiertas')
			}
		},
		{
			text: 'O si deseas encuentrala aquí',
		},
		{
			right: true,
			text: 'Busco en la ciudad de:'
		},
		{
			visible: !stores && !citySelected ? true : false,
			right: true,
			input: { 
				value: cityTerm,
				onChange: e => setCityTerm(e.target.value),
			},
			onSubmit: () => {
				setCitySelected(cityTerm)
				handleSearch(perPage)
			},
		},
		{
			visible: citySelected ? true : false,
			text: citySelected,
			onRollBack: () => {
				setCitySelected(null)
				handleReload()
			},
			right: true
		},
		{
			// visible: citySelected ? true : false,
			visible: false,
			right: true,
			text: '¿Alguna tienda en especial?'
		},
		{
			// visible: !stores && citySelected ? true : false,
			visible: false,
			right: true,
			input: { 
				value: term,
				onChange: e => setTerm(e.target.value),
				placeholder: 'Ej: Unicentro'
			},
			loading: loading,
			message: message,
			onSubmit: () => handleSearch(perPage),
			delay: 1000,
		},
		{
			// visible: stores ? true : false,
			visible: false,
			right: true,
			text: term,
			onRollBack: handleReload
		},
		{
			visible: stores ? true : false,
			text: 'Gracias, seleccione una tienda'
		},
		{
			visible: stores && !selectedStore ? true : false,
			render: () => (
				<StoresList 
					stores={stores}
					meta={meta}
					setSelectedStore={setSelectedStore}
					handleNextPage={handleNextPage}
					handleReload={handleReload}
				/>
			)
		},
		{
			visible: selectedStore ? true : false,
			right: true,
			text: selectedStore && selectedStore.name,
			onRollBack: () => setSelectedStore(null)
		},
		{
			visible: selectedStore ? true : false,
			text: 'Muy bien, te confirmo los datos de la tienda son:',
			delay: 500
		},
		{
			visible: selectedStore && selectedStore.mobile ? true : false,
			text: `Celular: ${selectedStore && selectedStore.mobile}`,
			delay: 500
		},
		{
			visible: selectedStore && selectedStore.phone ? true : false,
			text: `Fijo: ${selectedStore && selectedStore.phone}`,
			delay: 500
		},
		{
			visible: selectedStore && selectedStore.address ? true : false,
			text: `Dirección: ${selectedStore && selectedStore.address}, ${selectedStore && selectedStore.city}`,
			delay: 500
		},
		{
			visible: selectedStore && selectedStore.schedule ? true : false,
			text: `Horarios: ${selectedStore && selectedStore.schedule}`,
			delay: 500
		},
		{
			visible: selectedStore && selectedStore.email ? true : false,
			text: `Correo: ${selectedStore && selectedStore.email}`,
			delay: 500
		},
		{
			visible: selectedStore && selectedStore.mobile ? true : false,
			option: {
				label: 'Contactar por Whatsapp',
				onClick: () => window.open(`https://wa.me/57${selectedStore && selectedStore.mobile}`)
			}
		},
		...closeMessages(selectedStore, 2000, score => setSurvey({ ...survey, score }))
	]
	
	return <Flow messages={messages} survey={survey} setSurvey={setSurvey} />
}