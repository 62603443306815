import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { faSignInAlt, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export default function UserTopBar() {
	const user = useSelector(state => state.auth.user)

	return (
		<React.Fragment>
			<Container>
				{ user ? (
					<React.Fragment>
						<div className="float-right pt-2">
							<Link to="/auth/logout">
								<FontAwesomeIcon icon={faSignOutAlt} />
								<span className="ml-1">Cerrar Sesión</span>
							</Link>
						</div>
						<p className="bold mb-0">Hola, {user.name.split(' ')[0]}</p>
						<p className="mb-0 small">CC {user.document}</p>
					</React.Fragment>
				) : (
					<React.Fragment>
						<div className="float-right">
							<Link to="/auth/login">
								<FontAwesomeIcon icon={faSignInAlt} />
								<span className="ml-1">Iniciar Sesión</span>
							</Link>
						</div>
					</React.Fragment>
				)}
			</Container>
		</React.Fragment>
	)
}

const Container = styled.div`
	background: #eff3fb;
	padding: 10px 15px;
	position: fixed;
	width: 100%;
	left: 0px;
	top: 67px;
	z-index: 7;
`