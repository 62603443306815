import React, { useState } from 'react'

import Flow from '../../components/Flow'
import closeMessages from '../partials/closeMessages'

export default function PtdiSpringFlow() {
	const [survey, setSurvey] = useState(null)
	
	const messages = [
		{
			right: true,
			text: 'Política de manejo de datos y protección'
		},
		{
			text: 'Puedes conocer nuestra política de manejo de datos ingresando a este link:'
		},
		{
			right: true,
			option: {
				label: 'Ingresa a este link',
				onClick: () => window.open('https://colchonesspring.vteximg.com.br/arquivos/TERMINOS-Y-CONDICIONES-PAGINA-WEB.pdf?v=0706')
			}
		},
		...closeMessages(true, 1000, score => setSurvey({ ...survey, score }))
	]
	
	return <Flow messages={messages} survey={survey} setSurvey={setSurvey} />
}