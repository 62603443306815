import React, { useState } from 'react'

import Flow from '../../components/Flow'
import closeMessages from '../partials/closeMessages'

export default function ActivePromosFlow() {
	const [survey, setSurvey] = useState(null)
	
	const messages = [
		{
			right: true,
			text: 'Información de promociones vigentes'
		},
		{
			text: 'Conoce todas las promociones vigentes abriendo este link:',
			delay: 500
		},
		{
			right: true,
			delay: 1000,
			option: {
				label: 'Abrir link con promociones',
				onClick: () => window.open('https://www.colchonesspring.com.co/promociones')
			}
		},
		...closeMessages(true, 1500, score => setSurvey({ ...survey, score }))
	]
	
	return <Flow messages={messages} survey={survey} setSurvey={setSurvey} />
}