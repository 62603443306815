import React, { useEffect } from 'react'

import SalesChatFlow from './flows/SalesChatFlow'
import ReachStoreFlow from './flows/ReachStoreFlow'
import BuyOnlineFlow from './flows/BuyOnlineFlow'
import ActivePromosFlow from './flows/ActivePromosFlow'
import ProductDetailsFlow from './flows/ProductDetailsFlow'
import HygienitechFlow from './flows/HygienitechFlow'
import PaymentTypesFlow from './flows/PaymentTypesFlow'
import DeliveryReachFlow from './flows/DeliveryReachFlow'
import OrderStatusFlow from './flows/OrderStatusFlow'
import ContactUpdateFlow from './flows/ContactUpdateFlow'
import DeliveryRequirementsFlow from './flows/DeliveryRequirementsFlow'
import MattressWarrantyFlow from './flows/MattressWarrantyFlow'
import OthersWarrantyFlow from './flows/OthersWarrantyFlow'
import WarrantyManualFlow from './flows/WarrantyManualFlow'
import WarrantyRequestFlow from './flows/WarrantyRequestFlow'
import OpenPqrWebapp from './flows/OpenPqrWebapp'
import UsedMattressFlow from './flows/UsedMattressFlow'
import SostenabilityInfoFlow from './flows/SostenabilityInfoFlow'
import PtdiSpringFlow from './flows/PtdiSpringFlow'
import UnsubscribeFlow from './flows/UnsubscribeFlow'
import ChangePolicyFlow from './flows/ChangePolicyFlow'
import MomentosInfoFlow from './flows/MomentosInfoFlow'
import OpenWarrantyReqWebapp from './flows/OpenWarrantyReqWebapp'

export default function ChatBot({ intent }) {

	useEffect(() => { window.scrollTo(0,0) }, [])
	
	switch (intent) {
		case 'reach_store': return <ReachStoreFlow /> // Encontrar la tienda más cercana
		case 'buy_online': return <BuyOnlineFlow />
		case 'sales_chat': return <SalesChatFlow /> // Chat para asesoría especializada en tu compra 
		case 'active_promos': return <ActivePromosFlow /> // Información de promociones vigentes
		case 'product_details': return <ProductDetailsFlow /> // Consulta sobre productos / características
		case 'hygienitech': return <HygienitechFlow /> // Hygienitech: servicio de higienización de los colchones
		case 'payment_types': return <PaymentTypesFlow /> // Consulta de medios de pago
		case 'delivery_reach': return <DeliveryReachFlow /> // Cobertura de entregas a nivel nacional
		case 'order_status': return <OrderStatusFlow /> // Seguimiento al estado del pedido
		case 'contact_update': return <ContactUpdateFlow /> // Requiero cambio de telefono de contacto o dirección de entrega del pedido
		case 'delivery_requirements': return <DeliveryRequirementsFlow /> // Condiciones de entrega de su pedido
		case 'mattress_warranty': return <MattressWarrantyFlow /> // Garantía de colchones
		case 'others_warranty': return <OthersWarrantyFlow /> // Garantía de otros productos
		case 'warranty_manual': return <WarrantyManualFlow /> // Conoce el manual de garantía
		case 'change_policy': return <ChangePolicyFlow /> // Información de la política de cambio
		case 'momentos_info': return <MomentosInfoFlow /> // Información de programa de fidelización
		case 'unsubscribe': return <UnsubscribeFlow /> // Cancelar suscripción de correos publicitarios
		case 'ptdi_spring': return <PtdiSpringFlow /> // Política de manejo de datos y protección
		case 'sostenability_info': return <SostenabilityInfoFlow /> // Información de nuestro modelo de sostenibilidad
		case 'used_mattress': return <UsedMattressFlow /> // Servicio de recolección/disposición final colchones usados
		
		case 'warranty_request': return <OpenWarrantyReqWebapp /> // Solicitar una garantía o modificación del producto en uso
		case 'create_pqr': return <OpenPqrWebapp /> // Radicar un PQR
		case 'pqr_status': return <OpenPqrWebapp /> // Información estado de PQR
	
		default:
			return <h4>Chat no encontrado</h4>
	}

}