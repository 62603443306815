import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { Button } from 'reactstrap'
import styled from 'styled-components'


export default function MainMenu({ closeMenu }) {
	const user = useSelector(state => state.auth.user)

	return (
		<React.Fragment>
			<Container>
				{ user ? (
					<UserContainer>
						<UserIcon>
							<FontAwesomeIcon icon={faUser} />
						</UserIcon>
						<UserName>{user.name}</UserName>
						<p>c.c. {user.document}</p>
						<Link to="/auth/logout" onClick={closeMenu}>
							<p className="mb-0 text-link">
								Cerrar Sesión
							</p>
						</Link>
					</UserContainer>
				) : (
					<UserContainer>
						<UserIcon>
							<FontAwesomeIcon icon={faUser} />
						</UserIcon>
						<Link to="/auth" onClick={closeMenu}>
							<p className="mb-0 text-link text-dark">Iniciar Sesión</p>
						</Link>
					</UserContainer>
				)}
				<MenuList>				
					{/* <li>
						<Link className="link" to="/chat/list" onClick={closeMenu}>
							Chats Recientes
						</Link>
					</li> */}
					<li>
						<p className="link" onClick={()=>window.open('https://www.colchonesspring.com.co/')}>
							Sitio Web Spring
						</p>
					</li>
				</MenuList>
				<div>
					<Link to="/chat/new">
						<Button color="primary" onClick={closeMenu}>
							Nuevo Chat
						</Button>
					</Link>
				</div>
			</Container>
		</React.Fragment>
	)
}

const Container = styled.div`
	position: fixed;
	top: 0px;
	left: 0px;
	height: 100vh;
	z-index: 9;	
	padding-top: 50px;
	width: 240px;
	background-color: white;
	text-align: center;
	-webkit-box-shadow: 3px 0 12px 0 #b3b3b3;
	box-shadow: 3px 0 12px 0 #b3b3b3;
	overflow-y: scroll;
`

const UserContainer = styled.div`
	padding: 40px 20px 30px;
	background-color: #f8f8f8;
`

const UserName = styled.h6`
	font-size: 17px;
	text-transform: uppercase;
	font-weight: 600;
`

const UserIcon = styled.div`
	height: 4.2rem;
	width: 4.2rem;
	font-size: 1.9rem;
	background: #f8f8f8;
	border: 4px solid #fff;
	border-radius: 50%;
	color: #e0e0e0;
	margin: 0px auto 15px;
	padding-top: 8px;
`

const MenuList = styled.ul`
	list-style: none;
	margin: 0px 0px 30px;
	padding: 0;
	& li {
		padding: 0;
    	margin: 0;
	}
	& li .link {
		display: inline-block;
		padding: 14px 0;
		margin: 0;
		border-bottom: 1px solid #e8e8e8;
		width: 100%;
		color: #e42537;
	}
	& li .link:hover {
		cursor: pointer;
		background-color: #333;
		color: #fff !important;
	}
`