import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router'
import ChatBot from '../chatbot'

import NewChatFlow from '../chatbot/NewChatFlow'
import UserTopBar from '../components/UserTopBar'
import ChatsList from './partials/ChatsList'
import OpenChat from './partials/OpenChat'

export default function ChatPages() {
	const token = useSelector(state => state.auth.token)

	return (
		<React.Fragment>
			<div className="my-5">
				<UserTopBar />
			</div>
			<Switch>
				<Redirect exact path="/chat" to="/chat/new" />

				<Route exact path="/chat/new" component={NewChatFlow} />
				<Route 
					exact 
					path="/chat/visits/:slug" 
					component={props => <ChatBot intent={props.match.params.slug} />} 
				/>

				{ !token && <Redirect to="/auth" /> }

				<Route exact path="/chat/list" component={ChatsList} />
				<Route exact path="/chat/:id" component={props => <OpenChat id={props.match.params.id} />} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}